import { AbsResetPasswordComponent } from './component/abs-reset-password/abs-reset-password.component';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {
  NbAuthComponent,

  NbRegisterComponent
} from '@nebular/auth';
import { AbsLoginComponent } from './component/abs-login/abs-login.component';
import { AbsLogoutComponent } from './component/abs-logout/abs-logout.component';
import { AbsRequestPasswordComponent } from './component/abs-request-password/abs-request-password.component';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: AbsLoginComponent,
      },
      {
        path: 'login',
        component: AbsLoginComponent,
      },
      {
        path: 'logout',
        component: AbsLogoutComponent,
      },
      {
        path: 'request-password',
        component: AbsRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: AbsResetPasswordComponent,
      }
    ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

// Senza l'hash il webpack non riesce a caricarsi correttamente dal path in cui si effettua refresh!
const config: ExtraOptions = {
  useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
