import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { EnvService } from '../../shared/env.service'
import { RouterParams } from '../../shared/router.params'
import { SessionService } from './session.service'
import { TokenRequest } from './token.service'

/**
 * Servizio di autenticazione utente (client-server)
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private sessionService: SessionService,
    private envService: EnvService
  ) { }

  private baseUrl = this.envService.apiUrl
  /**
   * Effettua una richiesta al server per ottenere un token autorizzativo.
   * @param request 
   */
  login(request: TokenRequest) {
    const body = new HttpParams()
      .set('grant_type', request.grant_type)
      .set('username', request.username)
      .set('password', request.password)

    return this.http.post(`${this.baseUrl}/oauth/token`, body)
      .pipe(map((res: any) => res))
  }

  /**
   * Logout utente (client-only).
   * Pulisce il session storage rimuovendo:
   * - Token autorizzativo
   * - Dati utente
   *
   * Effettua quindi un redirect alla login.
   */
  logout() {
    this.sessionService.delete()
    this.router.navigate([RouterParams.PATH_LOGIN])
  }

  /**
   * Nuova password.
   * Invia una email all'utente con il password reset secret.
   * Il servizio prende in input un campo che puo' essere valorizzato con 
   * email/username/telefono, ma viene valorizzato come username nel JSON e
   * la distinzione del valore avviene tramite regex lato server.
   * @param multiField
   */
  requestPassword(multiField: string) {
    const body = new HttpParams()
      .set('username', multiField)

    return this.http.post(`${this.baseUrl}/v1/passwordreset`, body)
      .pipe(map((res: any) => res))
  }

  /**
   * Reset password
   * Cambia la password utente con la nuova password fornita.
   * @param username 
   * @param secret 
   * @param password 
   */
  resetPassword(
    username: string,
    secret: string,
    password: string
  ) {
    const body = new HttpParams()
      .set('username', username)
      .set('secret', secret)
      .set('password', password)

    return this.http.post(`${this.baseUrl}/v1/passwordupdate`, body)
      .pipe(map((res: any) => res))
  }
}
