import { Company } from './../model/company.model';
import { map } from 'rxjs/operators';
import { ApiService, ApiResponse } from './api.service';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private api: ApiService) { }

  private baseUrl1 = '/v1/companies';
  private baseUrl2 = '/v2/companies';

  get(companyId: number) {
    return this.api.get(`${this.baseUrl1}/${companyId}`, null)
      .pipe(map((res: any) => res));
  }

  getTemplate(companyId: number) {
    return this.api.get(`${this.baseUrl2}/${companyId}/template_configuration`, null)
      .pipe(map((res: any) => res));
  }

}
