<h1 id="title" class="title">{{ 'message.newPassword' | translate }}</h1>
<p class="sub-title">{{ 'message.resetPasswordForm' | translate }}</p>

<form (ngSubmit)="resetPassword()" [formGroup]="form" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label">Username:</label>
        <input nbInput fullWidth formControlName="username" placeholder="Username" fieldSize="large"
            [status]="form.get('username').invalid ? 'danger' : 'success'" autofocus>
    </div>
    <div class="form-control-group">
        <label class="label">{{ 'Codice' | translate }}:</label>
        <input nbInput fullWidth formControlName="secret" placeholder="{{ 'Codice' | translate }}"
            fieldSize="large" [status]="form.get('secret').invalid ? 'danger' : 'success'" autofocus>
    </div>
    <div class="form-control-group">
        <label class="label">{{ 'message.newPassword' | translate }}:</label>
        <input nbInput fullWidth formControlName="password" type="password"
            placeholder="{{ 'message.newPassword' | translate }}" fieldSize="large"
            [status]="form.get('password').invalid ? 'danger' : 'success'" autofocus>
    </div>
    <button nbButton fullWidth status="primary" size="large" [disabled]="!form.valid">
        {{ 'Invia' | translate }}
    </button>
</form>

<section class="sign-in-or-up">
    <p><a class="text-link" routerLink="../login">{{ 'message.backToLogin' | translate }}</a></p>
    <p><a class="text-link" routerLink="../request-password">{{ 'message.askAgain' | translate }}</a></p>
</section>