import { NgModule } from '@angular/core';
import { AbsToastrService } from './abs-toastr.service';
import { DateFormatterComponent } from './date-formatter/local-date.component';
import { DateTimeFormatterComponent } from './date-formatter/local-datetime.component';
import { PrimeNGModificationService } from './primeNGModifications.service';
import { SpinnerService } from './spinner.service';

@NgModule({
  imports: [],
  providers: [
    AbsToastrService,
    SpinnerService,
    PrimeNGModificationService
  ],
  declarations: [
    DateFormatterComponent,
    DateTimeFormatterComponent
  ],
})
export class SharedModule {
}
