import { LOCAL_LANGUAGE_KEY } from './../../../app.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Subject, throwError } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../../@core/utils';
import { Language } from '../../../model/language.model';
import { SessionService } from './../../../service/auth/session.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../shared/env.service';
import { TokenService } from '../../../service/auth/token.service';
import { CompanyService } from '../../../service/company.service';
import { ApiResponse } from '../../../service/api.service';
import { Title } from '@angular/platform-browser';
import { RouterParams } from '../../../shared/router.params';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  curLang: string;

  // Global variables
  companyId: number
  app_title: string
  navbar_logo: any
  navbar_menu_color: string
  navbar_color: string

  languages: Language[] = [];

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  
  userMenu = [
    { title: 'Log out', link: '/auth/logout' }
  ];
  
  constructor(
    private titleService: Title,
    private http: HttpClient,
    private envService: EnvService,
    private tokenService: TokenService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private router: Router,
    ) {
      this.companyId = this.sessionService.get().userData.companyId;
      /**
       * Route guard: verifica che ci sia una sessione utente (client-only)
       */
      if (!sessionService.isActive()) {
        router.navigate([RouterParams.PATH_LOGIN])
      }
      // Setta i dati utente sull'header
      else {
        this.user = {
          name: this.sessionService.get().userData.username,
          picture: ""
        }
      }
      this.fetchTemplate()
    }
    
    ngOnInit() {
      // Setup <tema> applicazione
      this.currentTheme = this.themeService.currentTheme;
      const { xl } = this.breakpointService.getBreakpointsMap();
      this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
        )
        .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
        
        this.themeService.onThemeChange()
        .pipe(
          map(({ name }) => name),
          takeUntil(this.destroy$),
          )
          .subscribe(themeName => this.currentTheme = themeName);
          // Setup <lingua> applicazione
          this.languages = [
            { code: 'it', label: 'Italiano' },
            { code: 'en', label: 'English' }
          ];
          this.curLang = this.translateService.currentLang
        }
        
        ngOnDestroy() {
          this.destroy$.next();
          this.destroy$.complete();
        }
        
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  changeLanguage(code: string) {
    localStorage.setItem(LOCAL_LANGUAGE_KEY, code)
    this.translateService.use(code)
  }

  fetchTemplate() {
    this.companyService.getTemplate(this.companyId).subscribe(
      (response: ApiResponse<any>) => {
        let data = response
        this.navbar_menu_color = data.data.navbar_menu_color
        this.navbar_logo = data.data.navbar_logo
        this.app_title = data.data.app_title
        this.titleService.setTitle(this.app_title);
      },
      (error: HttpErrorResponse) => { })
  }

  downloadPDF(): any {
    this.http.get(`${this.envService.apiUrl}/v2/companies/${this.companyId}/operational_manual`, { headers: new HttpHeaders().set('Authorization', `Bearer ${this.tokenService.getAccessToken()}`) }).subscribe(
      (response: any) => {
        let data = response.data.uri
        if (data) {
          let mediaType = 'application/pdf';
          this.http.get(data, { responseType: 'blob', headers: new HttpHeaders().set('Authorization', `Bearer ${this.tokenService.getAccessToken()}`) }).subscribe(
            (response) => {
              let blob = new Blob([response], { type: mediaType });
              FileSaver.saveAs(blob, 'Manuale.pdf');
            },
            e => { throwError(e); }
          );
        }
      },
      e => { throwError(e); }
    );
  }

}
